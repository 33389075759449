import React from "react";
import useLang from "../../contexts/Language/LanguageContext";

export default function TransactionError() {
  const lang = useLang();
  return (
    <div>
      <h1>{lang === "mne" ? "Transakcija neuspješna" : "Transaction error"}</h1>
      <p>
        {lang === "mne"
          ? `Nažalost, Vaša transakcija nije uspjela. Molimo Vas da pokušate ponovo ili da kontaktirate Vašu banku.  `
          : `Unfortunately, your transaction was not successful. Please try again or contact your bank. `}
      </p>
    </div>
  );
}
